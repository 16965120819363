<template>
  <div>
    <div class="result">
      <md-field class="margin-top">
        <md-cell-item title="建筑评判属性">
          <div slot="children">
            <p class="p-text">建筑名称：{{ params.name }}</p>
            <p class="p-text">地上层数：{{ params.c }}</p>
            <p class="p-text">地下层数：{{ params.c1 }}</p>
            <p class="p-text">高度 (m)：{{ params.h }}</p>
            <p class="p-text">地下深度 (m)：{{ params.h1 }}</p>
            <p class="p-text">标准层面积 (㎡)：{{ params.s }}</p>
            <p class="p-text">建筑总面积 (㎡)：{{ params.s1 }}</p>
            <p class="p-text">体积 (m³)：{{ params.v }}</p>
            <p class="p-text">建筑功能：{{ params.type }}</p>
            <p class="p-text" v-if="buildingDesc">建筑特性：{{ buildingDesc }}</p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="该建筑属性描述">
          <div slot="children">
            <div v-if="params.level === 1">
              <p class="p-text">该建筑属于一类高层{{ params.isHouse ? '住宅' : '公共' }}建筑耐火等级应为<span>一级</span></p>
              <p class="p-text" v-if="isImportant1 && !params.isHouse">属于<span>重要公共建筑</span></p>
            </div>
            <div v-else-if="params.level === 2">
              <!-- <p class="p-text" v-if="isImportant2 && !params.isHouse">属于<span>重要公共建筑</span></p> -->
              <p class="p-text">该建筑属于二类高层{{ params.isHouse ? '住宅' : '公共' }}建筑耐火等级不应小于<span>二级</span></p>
            </div>
            <div v-else>
              <p class="p-text">该建筑属于单多层{{ params.isHouse ? '住宅' : '公共' }}建筑</p>
              <p class="p-text" v-if="isImportant3 && !params.isHouse">属于<span>重要公共建筑</span></p>
              <p class="p-text" v-if="isFireLevel3">属于<span>耐火等级为三级</span></p>
              <p class="p-text" v-if="isFireLevel4">属于<span>耐火等级为四级</span></p>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="防火分区面积" arrow @click="handlePopupShow('防火分区面积')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <div v-if="params.level === 3">
              <p class="p-text" v-if="isFireLevel3"><span>1200 ㎡ </span>（设喷淋2400）</p>
              <p class="p-text" v-else-if="isFireLevel4"><span>600 ㎡ </span>（设喷淋1200）</p>
              <p class="p-text" v-else><span>2500 ㎡ </span>（设喷淋5000）</p>
              <p class="p-text" v-if="params.h1 > 0">地下 <span>500 ㎡ </span>（设喷淋1000）</p>
            </div>
            <div v-else>
              <p class="p-text"><span>1500 ㎡ </span>（设喷淋3000）</p>
              <p class="p-text" v-if="params.h1 > 0">地下 <span>500 ㎡ </span>（设喷淋1000）</p>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top" v-if="params.level < 3 || (params.level === 3 && params.fireLevel)">
        <md-cell-item title="最多允许层数" arrow @click="handlePopupShow('最多允许层数')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <div v-if="params.level < 3">
              <p class="p-text">不限</p>
            </div>
            <div v-else>
              <p class="p-text" v-if="isFireLevel3">5层</p>
              <p class="p-text" v-else-if="isFireLevel4">2层</p>
              <p class="p-text" v-else>不限</p>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top" v-if="params.level < 3">
        <md-cell-item title="消防车道形式" arrow @click="handlePopupShow('消防车道')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text">环形或两长边消防车道</p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top" v-if="params.level < 3">
        <md-cell-item title="救援场地" arrow @click="handlePopupShow('救援场地')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text">
              应在
              <span>{{ result.length }} m </span>范围上（不小于建筑一个长边的长度）连续布置消防车登高操作场地，该范围内的裙房进深不大于4m
            </p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="消防救援窗口" arrow @click="handlePopupShow('消防救援窗口')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p">建筑的外墙应在每层的适当位置设置可供消防救援人员进入的窗口</p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="防火间距要求" arrow @click="handlePopupShow('防火间距')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <div v-if="params.level < 3">
              <p class="p-text">与一二级高层建筑 <span>13 m</span></p>
              <p class="p-text">与一二级单多层建筑 <span>9 m</span></p>
              <p class="p-text">与三级单多层建筑 <span>11 m</span></p>
              <p class="p-text">与四级单多层建筑 <span>14 m</span></p>
            </div>
            <div v-else>
              <p class="p-text" v-if="params.fireLevel">
                与一二级高层建筑 <span>{{ isFireLevel3 ? 11 : 14 }} m</span>
              </p>
              <p class="p-text" v-else>与一二级高层建筑 <span>9 m</span></p>
              <p class="p-text" v-if="params.fireLevel">
                与一二级单多层建筑 <span>{{ isFireLevel3 ? 7 : 9 }} m</span>
              </p>
              <p class="p-text" v-else>与一二级单多层建筑 <span>6 m</span></p>
              <p class="p-text" v-if="params.fireLevel">
                与三级单多层建筑 <span>{{ isFireLevel3 ? 8 : 10 }} m</span>
              </p>
              <p class="p-text" v-else>与三级单多层建筑 <span>7 m</span></p>
              <p class="p-text" v-if="params.fireLevel">
                与四级单多层建筑 <span>{{ isFireLevel3 ? 10 : 12 }} m</span>
              </p>
              <p class="p-text" v-else>与四级单多层建筑 <span>9 m</span></p>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="疏散楼梯形式" arrow @click="handlePopupShow('疏散楼梯')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <div v-if="params.level === 1">
              <p class="p-text">应设防烟楼梯间</p>
            </div>
            <div v-else-if="params.level === 2">
              <p class="p-text" v-if="params.h <= 32 && !params.isHouse">应设封闭楼梯间</p>
              <p class="p-text" v-else-if="params.h <= 33 && params.isHouse">应设封闭楼梯间</p>
              <p class="p-text" v-else>应设防烟楼梯间</p>
            </div>
            <div v-else>
              <template v-if="params.isHouse">
                <p class="p-text" v-if="params.h <= 21">设置敞开楼梯间</p>
                <p class="p-text" v-else>设置封闭楼梯间</p>
              </template>
              <template v-else-if="params.isOldBuild">
                <p class="p-text">设置与外廊相通的敞开楼梯或封闭楼梯</p>
              </template>
              <template v-else>
                <p
                  class="p-text"
                  v-if="
                    params.c < 6 &&
                      !params.isShop &&
                      !params.isHotel &&
                      !params.isHospital &&
                      !params.isEntertainment &&
                      !params.isExhibit &&
                      !params.isHall &&
                      !params.isBook
                  "
                >
                  设置敞开楼梯间
                </p>
                <p class="p-text" v-else>设置封闭楼梯间</p>
              </template>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="内部疏散要求" arrow @click="handlePopupShow('内部疏散')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <div v-if="params.level === 1">
              <p class="p-text">
                出口间门—安全出口 <span>{{ evacuation.level1.e1 }} {{ isNaN(evacuation.level1.e1) ? '' : 'm' }}</span>
              </p>
              <p class="p-text">
                袋形走道门-安全出口 <span>{{ evacuation.level1.e2 }} {{ isNaN(evacuation.level1.e2) ? '' : 'm' }}</span>
              </p>
              <p class="p-text">
                房间内最远—房间门 <span>{{ evacuation.level1.e3 }} {{ isNaN(evacuation.level1.e3) ? '' : 'm' }}</span>
              </p>
            </div>
            <div v-else-if="params.level === 2">
              <p class="p-text">
                出口间门—安全出口 <span>{{ evacuation.level2.e1 }} {{ isNaN(evacuation.level2.e1) ? '' : 'm' }}</span>
              </p>
              <p class="p-text">
                袋形走道门-安全出口 <span>{{ evacuation.level2.e2 }} {{ isNaN(evacuation.level2.e2) ? '' : 'm' }}</span>
              </p>
              <p class="p-text">
                房间内最远—房间门 <span>{{ evacuation.level2.e3 }} {{ isNaN(evacuation.level2.e3) ? '' : 'm' }}</span>
              </p>
            </div>
            <div v-else>
              <p class="p-text">
                出口间门—安全出口 <span>{{ evacuation.level3.e1 }} {{ isNaN(evacuation.level3.e1) ? '' : 'm' }}</span>
              </p>
              <p class="p-text">
                袋形走道门-安全出口 <span>{{ evacuation.level3.e2 }} {{ isNaN(evacuation.level3.e2) ? '' : 'm' }}</span>
              </p>
              <p class="p-text">
                房间内最远—房间门 <span>{{ evacuation.level3.e3 }} {{ isNaN(evacuation.level3.e3) ? '' : 'm' }}</span>
              </p>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top" v-if="params.hasCourtyard">
        <md-cell-item title="中庭设置要求" arrow @click="handlePopupShow('中庭')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text">建筑内中庭叠加建筑面积大于防火分区时，应符合下列规定：</p>
            <p class="p-text">
              1.与周围连通空间采用防火隔墙时，其耐火极限不应低于1.00h；采用防火玻璃墙时，其耐火隔热性和耐火完整性不应低于1.00h，采用耐火完整性不低于1.00h的非隔热性防火玻璃墙时，应设置自动喷水灭火系统进行保护；采用防火卷帘时，其耐火极限不应低于3.00h；与中庭相连通的门、窗，应采用火灾时能自行关闭的甲级防火门、窗；
            </p>
            <p class="p-text">2.高层建筑内的中庭回廊应设置自动喷水灭火系统和火灾自动报警系统；</p>
            <p class="p-text">3.中庭应设置排烟设施；</p>
            <p class="p-text">4.中庭内不应布置可燃物。</p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="消防电梯设置" arrow @click="handlePopupShow('消防电梯')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <div v-if="params.level === 1">
              <p class="p-text">需要设置消防电梯</p>
            </div>
            <div v-else-if="params.level === 2">
              <template v-if="params.h1 > 10 && params.c1 * params.s > 3000">
                <p class="p-text">需要设置消防电梯</p>
              </template>
              <template v-else>
                <p class="p-text" v-if="params.h <= 32 && !params.isHouse">不需要设置消防电梯</p>
                <p class="p-text" v-else-if="params.h <= 33 && params.isHouse">不需要设置消防电梯</p>
                <p class="p-text" v-else>需要设置消防电梯</p>
              </template>
            </div>
            <div v-else>
              <p class="p-text" v-if="params.h1 > 10 && params.c1 * params.s > 3000">需要设置消防电梯</p>
              <p class="p-text" v-else>不需要设置消防电梯</p>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top" v-if="params.h > 100 && !params.isHouse">
        <md-cell-item title="避难层设置" arrow @click="handlePopupShow('避难层设')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text">应设置避难层</p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top" v-if="params.h > 100 && params.s > 2000 && !params.isHouse">
        <md-cell-item title="直升机停机坪" arrow @click="handlePopupShow('直升机停机坪')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text">宜设置直升机停机坪</p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="消火栓系统设置" arrow @click="handlePopupShow('消火栓系统')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p
              class="p-text"
              v-if="
                (params.level < 3 && params.v > 5000) ||
                  (params.level === 3 && ((isFireLevel3 && params.v < 50000) || (isFireLevel4 && params.v < 20000) || !params.fireLevel))
              "
            >
              室外消防用水量为 <span>{{ result.fireHydrant1 }} l/s</span>
            </p>
            <template v-if="hasInDoor">
              <p class="p-text">
                室内消防用水量为 <span>{{ result.fireHydrant2 }} l/s</span>
              </p>
              <p class="p-text">
                消火栓的保护半径为 <span>{{ result.fireHydrant3 }} m</span>（采用25m水带）
              </p>
              <p class="p-text">
                消火栓的平均布置间距为 <span>{{ result.fireHydrant4 }} m</span>（采用25m水带）
              </p>
              <p class="p-text">
                每层约设 <span>{{ result.fireHydrant5 }}</span> 个室内栓（按双股水流）
              </p>
              <p class="p-text">
                总体约设 <span>{{ result.fireHydrant6 }}</span> 个室内栓（按双股水流）
              </p>
              <p class="p-text">
                高位水箱容积应为 <span>{{ result.fireHydrant7 }} m³</span>
              </p>
              <p class="p-text">
                最不利点压力 <span>{{ result.fireHydrant8 }} MP</span>
              </p>

              <p class="p-text">
                水泵压力至少为 <span>{{ result.fireHydrant9 }} MP</span>
              </p>
              <p class="p-text">
                水泵流量至少为 <span>{{ result.fireHydrant2 }} l/s</span>
              </p>
            </template>
            <!-- 旅馆建筑 -->
            <p
              class="p-text"
              v-if="
                (params.level === 1 &&
                  ((params.isHotel && params.hasAir) || params.isFinancial || params.isReserch || params.isBook || params.isArchive)) ||
                  (params.level < 3 && (params.isShop || params.isComplex || params.isExhibit))
              "
            >
              火灾持续时间 <span>3 小时</span>
            </p>
            <p class="p-text" v-else>火灾持续时间 <span>2 小时</span></p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top" v-if="params.h > 100">
        <md-cell-item title="消防软管卷盘" arrow @click="handlePopupShow('消防软管卷盘')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text">应设置消防软管卷盘或轻便消防水龙</p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top" v-if="!params.noSpray">
        <md-cell-item title="自喷系统设置" arrow @click="handlePopupShow('自喷系统')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <div v-if="params.sprayLevel === 1">
              <p class="p-text">场所危险等级 <span>轻危</span></p>
              <p class="p-text">喷头喷水强度 <span>4 l/min.㎡</span></p>
              <p class="p-text">喷头作用面积 <span>160 ㎡</span></p>
              <p class="p-text">一只喷头保护面积 <span>20 ㎡</span></p>
              <p class="p-text">
                该建筑约需装 <span>{{ result.sprinkle1 }}</span> 个喷头
              </p>
              <p class="p-text">
                该建筑约需装 <span>{{ result.sprinkle2 }}</span> 个报警阀
              </p>
              <p class="p-text">喷淋泵最小流量 <span>10.67 l/s</span></p>
              <p class="p-text">
                喷淋泵最小压力 <span>{{ result.sprinkle3 }} Mp</span>
              </p>
            </div>
            <div v-else-if="params.sprayLevel === 2">
              <p class="p-text">场所危险等级 <span>中危I级</span></p>
              <p class="p-text">喷头喷水强度 <span>6 l/min.㎡</span></p>
              <p class="p-text">喷头作用面积 <span>160 ㎡</span></p>
              <p class="p-text">一只喷头保护面积 <span>12.5 ㎡</span></p>
              <p class="p-text">
                该建筑约需装 <span>{{ result.sprinkle1 }}</span> 个喷头
              </p>
              <p class="p-text">
                该建筑约需装 <span>{{ result.sprinkle2 }}</span> 个报警阀
              </p>
              <p class="p-text">喷淋泵最小流量 <span>16 l/s</span></p>
              <p class="p-text">
                喷淋泵最小压力 <span>{{ result.sprinkle3 }} Mp</span>
              </p>
            </div>
            <div v-else>
              <p class="p-text">场所危险等级 <span>中危Ⅱ级</span></p>
              <p class="p-text">喷头喷水强度 <span>8 l/min.㎡</span></p>
              <p class="p-text">喷头作用面积 <span>160 ㎡</span></p>
              <p class="p-text">一只喷头保护面积 <span>11.5 ㎡</span></p>
              <p class="p-text">
                该建筑约需装 <span>{{ result.sprinkle1 }}</span> 个喷头
              </p>
              <p class="p-text">
                该建筑约需装 <span>{{ result.sprinkle2 }}</span> 个报警阀
              </p>
              <p class="p-text">喷淋泵最小流量 <span>21.3 l/s</span></p>
              <p class="p-text">
                喷淋泵最小压力 <span>{{ result.sprinkle3 }} Mp</span>
              </p>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="消防水池（用水量）" arrow @click="handlePopupShow('消防水池')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text">
              室外消火栓系统所需水量 <span>{{ result.pool1 }} m³</span>
            </p>
            <p class="p-text" v-if="hasInDoor">
              室内消火栓系统所需水量 <span>{{ result.pool2 }} m³</span>
            </p>
            <p class="p-text" v-if="!params.noSpray">
              喷淋系统所需水量 <span>{{ result.pool4 }} m³</span>
            </p>
            <p class="p-text">
              总水量 <span>{{ result.pool3 }} m³</span>{{ `（室外栓${hasInDoor ? '+室内栓' : ''}${!params.noSpray ? '+喷淋' : ''}）` }}
            </p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="水泵接合器设置" arrow @click="handlePopupShow('水泵接合器')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text" v-if="hasInDoor">
              至少需要设置 <span>{{ result.pump1 }} 个</span> 消火栓水泵接合器
            </p>
            <template v-if="!params.noSpray">
              <div v-if="params.level < 3">
                <p class="p-text">至少需要设置 <span>2 个</span> 喷淋水泵接合器</p>
              </div>
              <div v-else>
                <p class="p-text">至少需要设置 <span>2 个</span> 喷淋水泵接合器</p>
              </div>
            </template>
          </div>
        </md-cell-item>
      </md-field>
      <md-field
        class="margin-top"
        v-if="
          !params.isHouse &&
            !params.isShop &&
            !params.isHotel &&
            !params.isHospital &&
            !params.isDispatch &&
            !params.isFinancial &&
            !params.isPostal &&
            !params.isTV &&
            !params.isReserch &&
            !params.isPTransport &&
            !params.isMDD &&
            !params.isMuseum &&
            !params.isArchive &&
            !params.isBook &&
            !params.isAncient &&
            !params.isKW &&
            !params.isCinema &&
            !params.isGym &&
            !params.isHall
        "
      >
        <md-cell-item title="火灾自动报警系统设置" arrow @click="handlePopupShow('火灾自动报警系统')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text" v-if="params.level < 3">应设集中报警系统</p>
            <p class="p-text">宜选择点型光电感烟火灾探测器</p>
            <p class="p-text">探测区域的每个房间应至少设置一只火灾探测器</p>
            <p class="p-text">探测器至墙壁、梁边的水平距离，不应小于 <span>0.5 m</span></p>
            <p class="p-text">感烟探测器的保护面积小于 <span>60 ㎡</span></p>
            <p class="p-text">探测器保护半径小于 <span>5.8 m</span></p>
            <p class="p-text">
              该建筑约需安装 <span>{{ result.alarm1 }} 个</span> 探测器
            </p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field
        class="margin-top"
        v-else-if="
          params.level === 1 ||
            (params.isShop && (params.s > 1500 || params.s1 > 3000 || params.c1 * params.s > 500)) ||
            ((params.isHotel ||
              params.isComplex ||
              params.isFinancial ||
              params.isReserch ||
              params.isPTransport ||
              params.isMDD ||
              params.isMuseum ||
              params.isExhibit) &&
              (params.s > 1500 || params.s1 > 3000)) ||
            (params.isHospital && (params.s > 1500 || params.s1 > 3000 || params.hasBed150)) ||
            (params.isDispatch && params.dispatchType) ||
            ((params.isPostal || params.isArchive) && params.city) ||
            (params.isTV && params.TVType) ||
            (params.isBook && params.bookNum) ||
            (params.isKW && (params.s > 1500 || params.s1 > 3000 || params.has200)) ||
            (params.isGym && params.seatNum > 3000) ||
            (params.isHall && params.seatNum > 2000) ||
            (params.isCinema && params.seatNum > 1500)
        "
      >
        <md-cell-item title="火灾自动报警系统设置" arrow @click="handlePopupShow('火灾自动报警系统')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text" v-if="params.level < 3">应设集中报警系统</p>
            <p class="p-text">宜选择点型光电感烟火灾探测器</p>
            <p class="p-text">探测区域的每个房间应至少设置一只火灾探测器</p>
            <p class="p-text">探测器至墙壁、梁边的水平距离，不应小于 <span>0.5 m</span></p>
            <p class="p-text">感烟探测器的保护面积小于 <span>60 ㎡</span></p>
            <p class="p-text">探测器保护半径小于 <span>5.8 m</span></p>
            <p class="p-text">
              该建筑约需安装 <span>{{ result.alarm1 }} 个</span> 探测器
            </p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top" v-else-if="params.isHouse">
        <md-cell-item title="火灾自动报警系统设置" arrow @click="handlePopupShow('火灾自动报警系统')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text" v-if="params.h > 100">应设集中报警系统</p>
            <p class="p-text" v-else-if="params.h > 54 && params.h <= 100">公共部位应设集中报警系统</p>
            <p class="p-text" v-if="params.h <= 54">宜设集中报警系统</p>
            <p class="p-text">宜选择点型光电感烟火灾探测器</p>
            <p class="p-text">探测区域的每个房间应至少设置一只火灾探测器</p>
            <p class="p-text">探测器至墙壁、梁边的水平距离，不应小于 <span>0.5 m</span></p>
            <p class="p-text">感烟探测器的保护面积小于 <span>60 ㎡</span></p>
            <p class="p-text">探测器保护半径小于 <span>5.8 m</span></p>
            <p class="p-text">
              该建筑约需安装 <span>{{ result.alarm1 }} 个</span> 探测器
            </p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field
        class="margin-top"
        v-if="
          (params.isHouse && params.h > 33) ||
            (!params.isHouse && params.h > 32) ||
            params.level === 1 ||
            (params.h1 > 10 && params.c1 * params.s > 3000)
        "
      >
        <md-cell-item title="防烟系统设置" arrow @click="handlePopupShow('防烟系统')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <p class="p-text">防烟楼梯间及其前室，消防电梯间及其前室/合用前室应设置防烟系统</p>
            <p class="p-text">注：若楼层高度大于 100m，每 100m 均按以下计算量为准</p>
            <p class="p-text">计算风量：</p>
            <p class="p-text">
              消防电梯前室 <span>{{ result.smoke1 }} m³/h</span>
            </p>
            <p class="p-text">
              各前室 <span>{{ result.smoke2 }} m³/h</span>
            </p>
            <p class="p-text">
              楼梯间 <span>{{ result.smoke3 }} m³/h</span>
            </p>
            <p class="p-text">设计风量：</p>
            <p class="p-text">
              消防电梯前室 <span>{{ result.smoke4 }} m³/h</span>
            </p>
            <p class="p-text">
              防烟前室 <span>{{ result.smoke5 }} m³/h</span>
            </p>
            <p class="p-text">
              楼梯间 <span>{{ result.smoke6 }} m³/h</span>
            </p>
            <p class="p-text">
              楼梯间风管最小尺寸 <span>{{ result.smoke7 }} m</span>（金属）
            </p>
            <p class="p-text">
              楼梯间风管最小尺寸 <span>{{ result.smoke8 }} m</span>（非金属）
            </p>
            <p class="p-text">
              前室风管最小尺寸 <span>{{ result.smoke9 }} m</span>（金属）
            </p>
            <p class="p-text">
              前室风管最小尺寸 <span>{{ result.smoke10 }} m</span>（非金属）
            </p>
            <p class="p-text">
              前室送风口最小尺寸 <span>{{ result.smoke11 }} m</span>
            </p>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="排烟系统设置" arrow @click="handlePopupShow('排烟系统')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <template v-if="!params.isHouse">
              <p class="p-text">面积大于100m²有人房间；面积大于300m²有可燃物房间；长度大于20m的疏散走道需要设置排烟。</p>
              <p class="p-text">
                计算排烟量 <span>{{ result.smoke_vol1 }} m³/h</span>
              </p>
              <p class="p-text">
                设计排烟量 <span>{{ result.smoke_vol2 }} m³/h</span>
              </p>
            </template>
            <template>
              <p class="p-text">长度大于20m的疏散走道需要设置排烟。机械排烟量不应小于<span>13000m³/h</span></p>
            </template>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="应急照明及疏散指示标志设置" arrow @click="handlePopupShow('应急照明及疏散指示标志')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <div v-if="params.level === 1">
              <p class="p-text">防烟楼梯间及其前室、消防电梯间的前室或合用前室不低于 <span>5 lx</span></p>
              <template v-if="!params.isHouse">
                <p class="p-text">避难走道、避难层（间）、展览厅、多功能厅不低于<span>5 lx</span></p>
                <p class="p-text">建筑面积大于100m²的地下或半地下公共活动场所不低于 <span>5 lx</span></p>
              </template>
              <p class="p-text">疏散走道不低于 <span>1 lx</span></p>
            </div>
            <div v-else-if="params.level === 2">
              <p class="p-text">封闭楼梯间、防烟楼梯间及其前室、消防电梯间的前室或合用前室不低于 <span>5 lx</span></p>
              <template v-if="!params.isHouse">
                <p class="p-text">避难走道；展览厅、多功能厅不低于<span>5 lx</span></p>
                <p class="p-text">建筑面积大于100m²的地下或半地下公共活动场所不低于 <span>5 lx</span></p>
              </template>

              <p class="p-text">疏散走道不低于 <span>1 lx</span></p>
            </div>
            <div v-else>
              <p class="p-text" v-if="params.isHouse">楼梯间不低于 <span>5 lx</span></p>
              <template v-else>
                <p class="p-text">封闭楼梯间不低于 <span>5 lx</span></p>
                <p class="p-text">避难走道；展览厅、多功能厅不低于<span>5 lx</span></p>
                <p class="p-text">建筑面积大于100m²的地下或半地下公共活动场所不低于 <span>5 lx</span></p>
              </template>
              <p class="p-text">疏散走道不低于 <span>1 lx</span></p>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="供配电设置" arrow @click="handlePopupShow('供配电')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <div v-if="params.level === 1">
              <p class="p-text">消防用电应按一级负荷供电，消防设备配电箱应独立设置</p>
            </div>
            <div v-else-if="params.level === 2">
              <p class="p-text">消防用电应按二级负荷供电，消防设备配电箱应独立设置</p>
            </div>
            <div v-else>
              <p class="p-text" v-if="params.isShop || params.isExhibit">
                可按{{ result.fireHydrant1 > 25 || params.s > 3000 ? '二' : '三' }}级负荷供电，消防设备配电箱宜独立设置
              </p>
              <p class="p-text" v-else-if="params.isFinancial">
                可按{{ result.fireHydrant1 > 25 || params.provincial ? '二' : '三' }}级负荷供电，消防设备配电箱宜独立设置
              </p>
              <p class="p-text" v-else-if="params.isPostal">
                可按{{ result.fireHydrant1 > 25 || params.city ? '二' : '三' }}级负荷供电，消防设备配电箱宜独立设置
              </p>
              <p class="p-text" v-else-if="params.isTV">
                可按{{ result.fireHydrant1 > 25 || params.TVType ? '二' : '三' }}级负荷供电，消防设备配电箱宜独立设置
              </p>
              <p class="p-text" v-else-if="params.isGym">
                可按{{ result.fireHydrant1 > 25 || params.seatNum > 3000 ? '二' : '三' }}级负荷供电，消防设备配电箱宜独立设置
              </p>
              <p class="p-text" v-else-if="params.isCinema">
                可按{{ result.fireHydrant1 > 25 || params.seatNum > 1500 ? '二' : '三' }}级负荷供电，消防设备配电箱宜独立设置
              </p>
              <p class="p-text" v-else>可按{{ result.fireHydrant1 > 25 ? '二' : '三' }}级负荷供电，消防设备配电箱宜独立设置</p>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="电气火灾监控设置" arrow @click="handlePopupShow('电气火灾监控')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <template
              v-if="
                !params.isHouse &&
                  !params.isShop &&
                  !params.isFinancial &&
                  !params.isPostal &&
                  !params.isTV &&
                  !params.isExhibit &&
                  !params.isOldBuild &&
                  !params.isGym &&
                  !params.isCinema
              "
            >
              <p class="p-text">{{ params.level === 1 || (params.level > 1 && result.fireHydrant1 > 25) ? '宜' : '否' }}</p>
            </template>
            <template v-else-if="params.isShop || params.isExhibit">
              <p class="p-text">
                {{ params.level === 1 || (params.level > 1 && (result.fireHydrant1 > 25 || params.s > 3000)) ? '宜' : '否' }}
              </p>
            </template>
            <template v-else-if="params.isFinancial">
              <p class="p-text">
                {{ params.level === 1 || (params.level > 1 && (result.fireHydrant1 > 25 || params.provincial)) ? '宜' : '否' }}
              </p>
            </template>
            <template v-else-if="params.isPostal">
              <p class="p-text">
                {{ params.level === 1 || (params.level > 1 && (result.fireHydrant1 > 25 || params.city)) ? '宜' : '否' }}
              </p>
            </template>
            <template v-else-if="params.isTV">
              <p class="p-text">
                {{ params.level === 1 || (params.level > 1 && (result.fireHydrant1 > 25 || params.TVType)) ? '宜' : '否' }}
              </p>
            </template>
            <template v-else-if="params.isOldBuild">
              <p class="p-text">应</p>
            </template>
            <template v-else-if="params.isGym">
              <p class="p-text">
                {{ params.level === 1 || (params.level > 1 && (result.fireHydrant1 > 25 || params.seatNum > 3000)) ? '宜' : '否' }}
              </p>
            </template>
            <template v-else-if="params.isCinema">
              <p class="p-text">
                {{ params.level === 1 || (params.level > 1 && (result.fireHydrant1 > 25 || params.seatNum > 1500)) ? '宜' : '否' }}
              </p>
            </template>
            <template v-else>
              <p class="p-text">{{ params.level === 1 ? '宜' : '否' }}</p>
            </template>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item
          title="建筑内消防应急照明和灯光疏散指示标志的备用电源的连续供电时间"
          arrow
          @click="handlePopupShow('建筑内消防应急照明和灯光疏散指示标志的备用电源的连续供电时间')"
          addon="详情"
        >
          <div slot="children" @click="e => e.stopPropagation()">
            <div v-if="params.level === 1">
              <p class="p-text" v-if="params.h > 100">不应小于 1.5小时</p>
              <p class="p-text" v-else-if="params.s1 > 10000 && !params.isHouse">不应小于 1小时</p>
              <p class="p-text" v-else>不应小于 0.5小时</p>
            </div>
            <div v-else>
              <p class="p-text" v-if="params.s1 > 10000 && !params.isHouse">不应小于 1小时</p>
              <p class="p-text" v-else>不应小于 0.5小时</p>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="场所装修要求" arrow @click="handlePopupShow('场所装修')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <div v-if="params.level === 1">
              <p class="p-text">
                顶棚 <span>{{ decoration.level1.d1 }}</span>
              </p>
              <p class="p-text">
                墙面 <span>{{ decoration.level1.d2 }}</span>
              </p>
              <p class="p-text">
                地面 <span>{{ decoration.level1.d3 }}</span>
              </p>
              <p class="p-text">
                隔断 <span>{{ decoration.level1.d4 }}</span>
              </p>
              <p class="p-text">
                固定家具 <span>{{ decoration.level1.d5 }}</span>
              </p>
            </div>
            <div v-else-if="params.level === 2">
              <p class="p-text">
                顶棚 <span>{{ decoration.level2.d1 }}</span>
              </p>
              <p class="p-text">
                墙面 <span>{{ decoration.level2.d2 }}</span>
              </p>
              <p class="p-text">
                地面 <span>{{ decoration.level2.d3 }}</span>
              </p>
              <p class="p-text">
                隔断 <span>{{ decoration.level2.d4 }}</span>
              </p>
              <p class="p-text">
                固定家具 <span>{{ decoration.level2.d5 }}</span>
              </p>
            </div>
            <div v-else>
              <p class="p-text">
                顶棚 <span>{{ decoration.level3.d1 }}</span>
              </p>
              <p class="p-text">
                墙面 <span>{{ decoration.level3.d2 }}</span>
              </p>
              <p class="p-text">
                地面 <span>{{ decoration.level3.d3 }}</span>
              </p>
              <p class="p-text">
                隔断 <span>{{ decoration.level3.d4 }}</span>
              </p>
              <p class="p-text">
                固定家具 <span>{{ decoration.level3.d5 }}</span>
              </p>
            </div>
          </div>
        </md-cell-item>
      </md-field>
      <md-field class="margin-top">
        <md-cell-item title="建筑保温要求" arrow @click="handlePopupShow('建筑保温')" addon="详情">
          <div slot="children" @click="e => e.stopPropagation()">
            <template
              v-if="
                params.isOffice ||
                  params.isComplex ||
                  params.isDispatch ||
                  params.isFinancial ||
                  params.isPostal ||
                  params.isTV ||
                  params.isReserch ||
                  params.isArchive
              "
            >
              <p class="p-text" v-if="params.h > 50">A级</p>
              <p class="p-text" v-else-if="params.h > 24 && params.h <= 50">无空腔B1级，有空腔应提高一级</p>
              <p class="p-text" v-else>无空腔B2级，有空腔应提高一级</p>
            </template>
            <template v-else-if="params.isHouse">
              <p class="p-text" v-if="params.h > 100">A级</p>
              <p class="p-text" v-else-if="params.h > 27 && params.h <= 100">B1</p>
              <p class="p-text" v-else>B2</p>
            </template>
            <template v-else>
              <p class="p-text">A级</p>
            </template>
          </div>
        </md-cell-item>
      </md-field>
    </div>
    <md-popup v-model="popupShow" position="bottom">
      <md-popup-title-bar only-close large-radius :title="popupTitle" title-align="left" @cancel="popupShow = false"></md-popup-title-bar>
      <div class="popup">
        <md-skeleton title :loading="loading" class="padding-lg">
          <div v-if="desc">
            <md-scroll-view :scrolling-x="false" auto-reflow>
              <div v-html="desc" class="rich-text"></div>
            </md-scroll-view>
          </div>
          <div v-else>
            <md-result-page></md-result-page>
          </div>
        </md-skeleton>
        <md-field title="您附近的消防企业" v-if="companies.length > 0">
          <md-cell-item v-for="(item, index) in companies" :key="index" :title="item.name" :brief="`电话：${item.phone}`" />
        </md-field>
      </div>
    </md-popup>
  </div>
</template>

<script>
import descriptionMixin from '@/mixins/descriptionMixin.js'

export default {
  mixins: [descriptionMixin],
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      },
    },
    result: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      popupShow: false,
      popupTitle: '',
      desc: '',
      companies: [],
    }
  },
  computed: {
    isImportant1() {
      return (
        this.params.isParty ||
        (this.params.isShop && this.params.s1 > 20000) ||
        this.params.hasBed150 ||
        this.params.hasEtmt1500 ||
        this.params.hasCShop ||
        this.params.provincial ||
        (this.params.isSchool && this.params.has500) ||
        (this.params.isFinancial && (this.params.has1500 || this.params.provincial)) ||
        (this.params.isTV && this.params.TVType === 'provincial') ||
        (this.params.isPTransport && this.params.has1500) ||
        ((this.params.isMuseum || this.params.isArchive || this.params.isExhibit) && this.params.city) ||
        (this.params.isAncient && this.params.ancientLevel) ||
        (this.params.isKid && this.params.has200) ||
        ((this.params.isCinema || this.params.isHall) && this.params.seatNum > 1500) ||
        (this.params.isGym &&
          ((this.params.openAir && this.params.seatNum > 5000) || (!this.params.openAir && this.params.seatNum > 1500))) ||
        (this.params.isBook && this.params.bookNum)
      )
    },
    isImportant2() {
      return (
        (this.params.isShop && this.params.s1 > 20000) ||
        this.params.hasCShop ||
        this.params.provincial ||
        this.params.hasEtmt1500 ||
        (this.params.isSchool && this.params.has500) ||
        (this.params.isFinancial && (this.params.has1500 || this.params.provincial)) ||
        (this.params.isTV && this.params.TVType === 'provincial') ||
        (this.params.isPTransport && this.params.has1500) ||
        ((this.params.isMuseum || this.params.isArchive) && this.params.city) ||
        ((this.params.isMuseum || this.params.isArchive || this.params.isExhibit) && this.params.city) ||
        (this.params.isAncient && this.params.ancientLevel) ||
        (this.params.isKid && this.params.has200) ||
        ((this.params.isCinema || this.params.isHall) && this.params.seatNum > 1500) ||
        (this.params.isGym &&
          ((this.params.openAir && this.params.seatNum > 5000) || (!this.params.openAir && this.params.seatNum > 1500))) ||
        (this.params.isBook && this.params.bookNum)
      )
    },
    isImportant3() {
      return (
        this.params.isParty ||
        (this.params.isShop && this.params.s1 > 20000) ||
        this.params.hasBed150 ||
        this.params.hasCShop ||
        this.params.hasEtmt1500 ||
        this.params.provincial ||
        (this.params.isSchool && this.params.has500) ||
        (this.params.isFinancial && (this.params.has1500 || this.params.provincial)) ||
        (this.params.isTV && this.params.TVType === 'provincial') ||
        (this.params.isPTransport && this.params.has1500) ||
        ((this.params.isMuseum || this.params.isArchive) && this.params.city) ||
        ((this.params.isMuseum || this.params.isArchive || this.params.isExhibit) && this.params.city) ||
        (this.params.isAncient && this.params.ancientLevel) ||
        (this.params.isKid && this.params.has200) ||
        ((this.params.isCinema || this.params.isHall) && this.params.seatNum > 1500) ||
        (this.params.isGym &&
          ((this.params.openAir && this.params.seatNum > 5000) || (!this.params.openAir && this.params.seatNum > 1500))) ||
        (this.params.isBook && this.params.bookNum)
      )
    },
    isFireLevel3() {
      return this.params.fireLevel === 'level3'
    },
    isFireLevel4() {
      return this.params.fireLevel === 'level4'
    },
    decoration() {
      const level1 = {
        d1: 'A',
        d2: 'B1',
        d3: 'B1',
        d4: 'B1',
        d5: 'B2',
      }
      const level2 = {
        d1: 'A',
        d2: 'B1',
        d3: 'B1',
        d4: 'B1',
        d5: 'B2',
      }
      const level3 = {
        d1: 'B1',
        d2: 'B1',
        d3: 'B2',
        d4: 'B2',
        d5: 'B2',
      }
      // 办公建筑
      if (this.params.isOffice && this.params.hasAir) {
        level3.d1 = 'A'
        level3.d2 = 'B1'
        level3.d3 = 'B1'
        level3.d4 = 'B1'
      }
      // 老年建筑 医院 幼儿园 儿童福利院
      if (this.params.isOldBuild || this.params.isHospital || this.params.isKW) {
        level1.d2 = 'A'
        level2.d2 = 'A'
        level3.d1 = 'A'
        level3.d2 = 'A'
        level3.d3 = 'B1'
        level3.d4 = 'B1'
      }
      // 旅馆建筑
      if (this.params.isHotel && this.params.hasAir) {
        level3.d1 = 'A'
        level3.d2 = 'B1'
        level3.d3 = 'B1'
        level3.d4 = 'B1'
      }
      // 客运建筑
      if (this.params.isPTransport) {
        if (this.params.s1 > 10000) {
          level1.d2 = 'A'
          level1.d5 = 'B1'

          level2.d2 = 'A'
          level2.d5 = 'B1'

          level3.d1 = 'A'
          level3.d2 = 'A'
          level3.d3 = 'B1'
          level3.d4 = 'B1'
          level3.d5 = 'B1'
        } else {
          level1.d5 = 'B1'

          level2.d5 = 'B1'

          level3.d1 = 'A'
          level3.d3 = 'B1'
          level3.d4 = 'B1'
          level3.d5 = 'B1'
        }
      }
      // 歌舞娱乐
      if (this.params.isEntertainment) {
        level1.d5 = 'B1'
        level2.d5 = 'B1'
        level3.d1 = 'A'
        level3.d3 = 'B1'
        level3.d4 = 'B1'
        level3.d5 = 'B1'
      }
      // 综合建筑
      if (this.params.isComplex) {
        level1.d5 = 'B1'
        level2.d5 = 'B1'
        if (this.params.s >= 1500) {
          level3.d1 = 'A'
          level3.d3 = 'B1'
          level3.d4 = 'B1'
          level3.d5 = 'B1'
        }
        if (this.params.s < 1500) {
          level3.d1 = 'A'
          level3.d2 = 'B1'
          level3.d3 = 'B1'
          level3.d4 = 'B1'
          level3.d5 = 'B2'
        }
      }

      // 金融 邮政 广电
      if (this.params.isFinancial || this.params.isPostal || this.params.isTV) {
        level1.d2 = 'A'
        level1.d5 = 'B1'
        level2.d3 = 'B2'
        level2.d4 = 'B2'
        if (this.params.hasAir) {
          level3.d1 = 'A'
          level3.d2 = 'B1'
          level3.d3 = 'B1'
          level3.d4 = 'B1'
          level3.d5 = 'B2'
        }
      }

      // 住宅
      if (this.params.isHouse) {
        level3.d3 = 'B1'
        level3.d4 = 'B1'
      }

      // 商店建筑
      if (this.params.isShop) {
        level1.d5 = 'B1'
        level2.d5 = 'B1'
        level3.d1 = 'A'
        level3.d2 = 'B1'
        level3.d3 = 'B1'
        level3.d4 = 'B1'
        level3.d5 = this.params.s <= 1500 && this.params.s1 <= 3000 ? 'B2' : 'B1'
      }

      // 教学建筑
      if (this.params.isSchool) {
        level1.d3 = 'B2'
        level1.d4 = 'B2'

        level2.d3 = 'B2'
        level2.d4 = 'B2'

        level3.d1 = 'A'
      }

      // 指挥调度、电力调度
      if (this.params.isDispatch) {
        level1.d2 = 'A'

        level2.d3 = 'B2'
        level2.d4 = 'B2'
      }

      // 餐厅 食堂
      if (this.params.isDining) {
        if (this.params.s > 100) {
          level3.d1 = 'A'
          level3.d3 = 'B1'
          level3.d4 = 'B1'
        } else {
          level3.d3 = 'B1'
        }
      }

      // 博物馆 档案馆 图书馆 展览馆
      if (this.params.isMuseum || this.params.isArchive || this.params.isBook || this.params.isExhibit) {
        level3.d1 = 'A'
        level3.d3 = 'B1'
        level3.d4 = 'B1'
      }

      //会堂
      if (this.params.isHall) {
        if (this.params.s > 400) {
          level1.d2 = 'A'
          level1.d5 = 'B1'

          level2.d2 = 'A'
          level2.d5 = 'B1'

          level3.d1 = 'A'
          level3.d2 = 'A'
          level3.d3 = 'B1'
          level3.d4 = 'B1'
          level3.d5 = 'B1'
        } else {
          level3.d1 = 'A'
          level3.d3 = 'B1'
          level3.d4 = 'B1'
        }
      }

      // 体育馆
      if (this.params.isGym) {
        if (this.params.seatNum > 3000) {
          level3.d1 = 'A'
          level3.d2 = 'A'
          level3.d3 = 'B1'
          level3.d4 = 'B1'
          level3.d5 = 'B1'
        } else {
          level3.d1 = 'A'
          level3.d3 = 'B1'
          level3.d4 = 'B1'
        }
      }

      return { level1, level2, level3 }
    },
    evacuation() {
      const level1 = {
        e1: 40,
        e2: 20,
        e3: 20,
      }
      const level2 = {
        e1: 40,
        e2: 20,
        e3: 20,
      }
      const level3 = {
        e1: 40,
        e2: 22,
        e3: 22,
      }
      if (this.isFireLevel3) {
        level3.e1 = 35
        level3.e2 = 20
        level3.e3 = 20
      }
      if (this.isFireLevel4) {
        level3.e1 = 25
        level3.e2 = 15
        level3.e3 = 15
      }

      // 老年建筑
      if (this.params.isOldBuild) {
        level1.e1 = 25

        level2.e1 = 25

        level3.e1 = 25
        level3.e2 = 20
        level3.e3 = 20
        if (this.isFireLevel3) {
          level3.e1 = 20
          level3.e2 = 15
          level3.e3 = 15
        }
        if (this.isFireLevel4) {
          level3.e1 = 15
          level3.e2 = 10
          level3.e3 = 10
        }
      }
      // 旅馆建筑
      if (this.params.isHotel) {
        level1.e1 = 30
        level1.e2 = 15
        level1.e3 = 15
        level2.e1 = 30
        level2.e2 = 15
        level2.e3 = 15
      }
      // 医院
      if (this.params.isHospital) {
        if (this.params.hospitalType === 'ward') {
          level1.e1 = 24
          level1.e2 = 12
          level1.e3 = 12
          level2.e1 = 24
          level2.e2 = 12
          level2.e3 = 12
        } else {
          level1.e1 = 30
          level1.e2 = 15
          level1.e3 = 15
          level2.e1 = 30
          level2.e2 = 15
          level2.e3 = 15
        }
        level3.e1 = 35
        level3.e2 = 20
        level3.e3 = 20
        if (this.isFireLevel3) {
          level3.e1 = 30
          level3.e2 = 15
          level3.e3 = 15
        }
        if (this.isFireLevel4) {
          level3.e1 = 25
          level3.e2 = 10
          level3.e3 = 10
        }
      }
      // 歌舞娱乐
      if (this.params.isEntertainment) {
        level1.e1 = 25
        level1.e2 = 9
        level1.e3 = 9
        level2.e1 = 25
        level2.e2 = 9
        level2.e3 = 9
        level3.e1 = 25
        level3.e2 = 9
        level3.e3 = 9
        if (this.isFireLevel3) {
          level3.e1 = 20
          level3.e2 = '不允许'
          level3.e3 = '不允许'
        }
        if (this.isFireLevel4) {
          level3.e1 = 15
          level3.e2 = '不允许'
          level3.e3 = '不允许'
        }
      }

      // 教学建筑
      if (this.params.isSchool) {
        level1.e1 = 30
        level1.e2 = 15
        level1.e3 = 15
        level2.e1 = 30
        level2.e2 = 15
        level2.e3 = 15
        level3.e1 = 35
        level3.e2 = 22
        level3.e3 = 22
        if (this.isFireLevel3) {
          level3.e1 = 30
          level3.e2 = 20
          level3.e3 = 20
        }
        if (this.isFireLevel4) {
          level3.e1 = 25
          level3.e2 = 10
          level3.e3 = 10
        }
      }

      // 展览馆
      if (this.params.isExhibit) {
        level1.e1 = 30
        level1.e2 = 15
        level1.e3 = 15
        level2.e1 = 30
        level2.e2 = 15
        level2.e3 = 15
        if (this.isFireLevel3) {
          level3.e1 = 35
          level3.e2 = 20
          level3.e3 = 20
        }
        if (this.isFireLevel4) {
          level3.e1 = 25
          level3.e2 = 15
          level3.e3 = 15
        }
      }

      // 幼儿园
      if (this.params.isKid) {
        level1.e1 = 25

        level2.e1 = 25

        level3.e1 = 25
        level3.e2 = 20
        level3.e3 = 20
      }

      // 儿童福利院
      if (this.params.isWelfare) {
        if (this.isFireLevel3) {
          level3.e1 = 35
          level3.e2 = 20
          level3.e3 = 20
        }
        if (this.isFireLevel4) {
          level3.e1 = 25
          level3.e2 = 15
          level3.e3 = 15
        }
      }

      if (!this.params.noSpray) {
        level1.e1 = 1.25 * level1.e1
        level1.e2 = 1.25 * level1.e2
        level1.e3 = 1.25 * level1.e3

        level2.e1 = 1.25 * level2.e1
        level2.e2 = 1.25 * level2.e2
        level2.e3 = 1.25 * level2.e3

        if (!isNaN(level3.e2) && !isNaN(level3.e3)) {
          level3.e1 = 1.25 * level3.e1
          level3.e2 = 1.25 * level3.e2
          level3.e3 = 1.25 * level3.e3
        }
      }
      return { level1, level2, level3 }
    },
    buildingDesc() {
      return `${this.params.hasCShop ? '商场面积大于15000㎡。' : ''}${this.params.hasCourtyard ? '该建筑有中庭。' : ''}
                ${this.params.hasAir ? '有集中空调。' : ''}${this.params.hasEtmt1500 ? '人数（座位）大于1500。' : ''}
                ${this.params.provincial ? '省级。' : ''}
                ${this.params.has1500 ? '人数大于1500。' : ''}${this.params.has500 ? '中小学人数大于500。' : ''}
                ${this.params.hasBed150 ? '床位大于150。' : ''}
                ${this.params.has200 ? '人数大于200。' : ''}
                ${this.params.indpt ? '独立建造。' : ''}${this.params.hasS1000 ? '24m以上，任一层面积大于1000㎡。' : ''}
                ${this.params.openAir ? '露天。' : ''}${this.params.provincial ? '省级及以上。' : ''}
                ${this.params.city ? '地市级及以上。' : ''}
                ${this.params.seatNum ? `座位数：${this.params.seatNum}。` : ''}

                ${this.params.hospitalType === 'ward' ? '病房楼。' : ''}
                ${this.params.hospitalType === 'outpatient' ? '门诊楼。' : ''}
                ${this.params.dispatchType === 'provincial' ? '省级/网局级及以上。' : ''}
                ${this.params.dispatchType === 'city' ? '城市/区域级。' : ''}
                ${this.params.TVType === 'provincial' ? '省级及以上。' : ''}
                ${this.params.TVType === 'city' ? '地市级及以上。' : ''}
                ${this.params.ancientLevel === 'city' ? '地市级及以上。' : ''}
                ${this.params.ancientLevel === 'country' ? '国家级。' : ''}
                ${this.params.bookNum === 'has50w' ? '藏书50万-100万册。' : ''}
                ${this.params.bookNum === 'has100w' ? '藏书100万册以上。' : ''}
              ${this.params.fireLevel === 'level3' ? '三级耐火等级。' : ''}${
        this.params.fireLevel === 'level4' ? '四级耐火等级。' : ''
      }`.trim()
    },
    hasInDoor() {
      return (
        (this.params.level < 3 ||
          (this.params.level === 3 &&
            (this.params.h > 15 ||
              this.params.v > 10000 ||
              ((this.params.isHall || this.params.isGym) && this.params.seatNum > 1200) ||
              (this.params.isCinema && this.params.seatNum > 800)))) &&
        this.result.fireHydrant2
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.result
  .md-field
    background-color #d6e4ff
.popup
  background-color #FFF
  .md-tabs >>> .md-tab-bar
    background-color #FFF
  .md-scroll-view
    height 700px
.md-field
  >>>.md-cell-item-right .md-icon-arrow-right
    color #333333
</style>
