import * as descriptionApi from '@/api/description.js'
import { imageSrc } from '@/utils/utils'

export default {
  data() {
    return {
      popupShow: false,
      popupTitle: '',
      desc: '',
      loading: false,
    }
  },
  methods: {
    handlePopupShow(name) {
      this.loading = true
      this.popupShow = true
      this.desc = ''
      this.companies = []
      this.popupTitle = name
      descriptionApi
        .getByName({ name })
        .then(res => {
          if (res) {
            this.desc = imageSrc(res.data.desc)
            this.companies = res.data.companies
          }
        })
        .finally(() => (this.loading = false))
    },
  },
}
